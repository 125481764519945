<template>
  <HeaderComponent></HeaderComponent>
  <div class="background">
    <div class="presentation" style=" flex-direction: column;">
      <h1 class="margins-left margins-top">Aprexi Web</h1>
      <div style="display: flex; flex-direction: column;">
        <div class="margins-left" style="max-width: 1100px;">
          <Carousel>
            <Slide v-for="(image, index) in images" :key="index">
              <div class="carousel__item">
                <img :src="image" :alt="'Imagen ' + (index + 1)" />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </div>

        <div :class="'descripcion-proyecto'">
          <h2>¿Qué es Aprexi Web?</h2>
          <p class="margin-top">{{ descripcionAprexi1 }}</p>
          <p class="margin-top">{{ descripcionAprexi2 }}</p>
          <div :style="{ padding: '0px', marginTop: '30px' }"></div>
          <h2>¿Qué funcionalidades tiene?</h2>
          <p class="margin-top">{{ descripcionAprexi3 }}</p>
          <ul>
            <li class="margin-top">
              <h5><strong>Acceso seguro y fácil:</strong></h5>
              <p>Los usuarios pueden registrarse o iniciar sesión de manera rápida y segura, con opciones de registro
                para escuelas, alumnos y empresas.</p>
            </li>
            <li class="margin-top">
              <h5><strong>Exploración de oportunidades:</strong></h5>
              <p>Ofrece un listado completo de ofertas de empleo y prácticas, con herramientas de filtrado que permiten
                a los usuarios encontrar fácilmente las oportunidades que mejor se adapten a sus necesidades.</p>
            </li>
            <li class="margin-top">
              <h5><strong>Organización personalizada:</strong></h5>
              <p>Los usuarios pueden guardar empresas y ofertas de interés para un acceso rápido y fácil en el futuro.
              </p>
            </li>
            <li class="margin-top">
              <h5><strong>Seguimiento del proceso:</strong></h5>
              <p>Proporciona una visión clara del progreso de los usuarios en el proceso de solicitud, mostrando las
                diferentes etapas, desde la visualización del CV hasta la inscripción y más allá.</p>
            </li>
            <li class="margin-top">
              <h5><strong>Creación y descarga de CV personalizados:</strong></h5>
              <p>Permite a los usuarios crear y descargar CV profesionales, incorporando toda su información relevante,
                como estudios, experiencia laboral, idiomas y más.</p>
            </li>
            <li class="margin-top">
              <h5><strong>Interacción con las ofertas:</strong></h5>
              <p>Permite a los usuarios visualizar detalles completos de las ofertas, inscribirse en ellas o guardarlas
                para referencia futura.</p>
            </li>
          </ul>

          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>¿Qué API utiliza?</h2>
            
            <p>
              Tanto la web como las aplicaciones de
              <router-link :to="{ name: 'aprexiAndroid' }" class="no-link-style"><b><u>Android</u></b></router-link>,
              <router-link :to="{ name: 'aprexiIOS' }" class="no-link-style"><b><u>iOS</u></b></router-link>
               y <router-link :to="{ name: 'aprexiFlutter' }" class="no-link-style"><b><u>Flutter</u></b></router-link>, se alimentan de una API común hecha con PHP y alojada en un servidor de IONOS.
            </p>
          </div>

          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>Tecnologias aplicadas</h2>
            <p class="margin-top">{{ descripcionAplicacion1 }}</p>
            <ul>
              <li class="margin-top">
                <h5><strong>Vue.js:</strong></h5>
                <p>Framework JavaScript progresivo para crear interfaces de usuario dinámicas y
                  reactivas.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Axios:</strong></h5>
                <p>Librería HTTP para realizar peticiones al servidor de forma eficiente y
                  segura.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Router:</strong></h5>
                <p>Sistema de gestión de rutas para navegar entre diferentes secciones de la
                  aplicación sin necesidad de recargar la página.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Vuex:</strong></h5>
                <p>Almacén de estado centralizado para administrar datos compartidos de forma
                  eficiente entre componentes.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Autenticación JWT:</strong></h5>
                <p>Implementación de un sistema de autenticación seguro basado en tokens JSON
                  Web.</p>
              </li>
              <li class="margin-top">
                <h5><strong>SessionStorage y LocalStorage:</strong></h5>
                <p>Mecanismos de almacenamiento web para persistir datos del usuario en el
                  navegador.</p>
              </li>
            </ul>
          </div>

          <div :class="'recuadro-tecnologias'">
            <div class="recuadro-proyectos">
              <h5>Axios</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Vuex</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Router</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Vue.js</h5>
            </div>
            <div>
              <img src="@/assets/icons/github.png" alt="Descripción de la imagen"
                style="height: 100px; cursor: pointer;" @click="abrirEnlace">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'ProyectosPage',
  components: {
    HeaderComponent,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    abrirEnlace() {
      window.open('https://github.com/NicolasLavilla/Aprexi.git', '_blank');
    }
  },
  data() {
    return {
      images: [
        require('../../assets/images/proyects/proyectAprexiWeb/Home.png'),
        require('../../assets/images/proyects/proyectAprexiWeb/ListOffers.png'),
        require('../../assets/images/proyects/proyectAprexiWeb/MyOffers.png'),
        require('../../assets/images/proyects/proyectAprexiWeb/CV.png'),
        require('../../assets/images/proyects/proyectAprexiWeb/Analisis.png'),
        require('../../assets/images/proyects/proyectAprexiWeb/Practicas.png'),
        require('../../assets/images/proyects/proyectAprexiWeb/ListProcess.png'),
        require('../../assets/images/proyects/proyectAprexiWeb/ProcessDetail.png'),
      ]
    }
  }
};
</script>

<script setup>
var descripcionAprexi1 = "Aprexi es una innovadora plataforma que actúa como un puente dinámico entre individuos en busca de empleo o nuevas oportunidades y las empresas que buscan talento. Su enfoque multisectorial no se limita a nichos específicos, sino que se abre a una amplia gama de industrias. Destacando especialmente como una valiosa herramienta para aquellos que están dando sus primeros pasos en el mundo laboral.";
var descripcionAprexi2 = "La fuerza de Aprexi radica en su capacidad para facilitar la conexión entre empleadores y candidatos a través de filtros eficientes, simplificando el proceso de selección y proporcionando a las empresas acceso a un talento diverso y prometedor.";
var descripcionAprexi3 = "Este emocionante proyecto ofrece una plataforma integral para facilitar el proceso de búsqueda de empleo y prácticas tanto para estudiantes como para empresas. Con un enfoque en la usabilidad y la eficiencia, esta aplicación incorpora una variedad de características clave para optimizar la experiencia de los usuarios:";
var descripcionAplicacion1 = "En el corazón de este proyecto web, he desarrollado una aplicación sólida y versátil que aprovecha el poder de las tecnologías front-end más populares para ofrecer una experiencia de usuario fluida y atractiva.";
//var descripcionAPIAplicacion = "Tanto la web como las aplicaciones de Android, iOS y Flutter, se alimentan de una API conmún hecha con PHP y alojada en un servidor de IONOS.";
</script>

<style>
@import url('@/assets/styles/displacements.css');
@import url('@/assets/styles/gravity.css');
@import url('@/assets/styles/styles_colors.css');
@import url('@/assets/styles/styles_letter.css');
@import url('@/assets/styles/styles_margins.css');
@import url('@/assets/styles/responsive_screen.css');

.recuadro-proyectos {
  padding: 40px;
  max-width: 20%;
  background-color: var(--accent-color);
  border-radius: 15px;
  margin: 10px;
  margin-inline: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-container {
  height: auto;
  overflow: hidden;
}

.carousel__item img {
  width: 100%;
  height: auto;
}
</style>

<style scoped>
.no-link-style {
  color: inherit; /* Usa el color del texto circundante */
  text-decoration: none; /* Elimina el subrayado */
}

.no-link-style:hover {
  text-decoration: none; /* Asegúrate de que no aparezca subrayado al pasar el ratón */
  color: inherit; /* Mantén el mismo color al pasar el ratón */
}
</style>