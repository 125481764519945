<template>
  <HeaderComponent></HeaderComponent>
  <div class="background">
    <div class="presentation">
      <div :class="'recuadro-presentacion-40-60'">
        <h1>Aprexi Flutter</h1>
        <div :style="{ display: 'flex', justifyContent: 'center' }">
          <div :class="'div-imagen'">
            <img src="@/assets/images/oo.png" alt="Descripción de la imagen" :class="'image-proyect'">
          </div>
        </div>
      </div>
      <div :class="'recuadro-presentacion-60-40'">
        <div :class="'descripcion-proyecto'">
          <h2>¿Qué es?</h2>
          <p class="margin-top">{{ descripcionAprexi1 }}</p>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>¿Qué funcionalidades tiene?</h2>
            <p class="margin-top">{{ descripcionAprexi3 }}</p>
            <ul>
              <li class="margin-top">
                <h5><strong>Acceso seguro y fácil:</strong></h5>
                <p>Los usuarios pueden registrarse o iniciar sesión de manera rápida y segura, con opciones de registro
                  para alumnos y trabajadores.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Organización personalizada:</strong></h5>
                <p>Los usuarios pueden guardar empresas y ofertas de interés para un acceso rápido y fácil en el futuro.
                </p>
              </li>
              <li class="margin-top">
                <h5><strong>Interacción con las ofertas:</strong></h5>
                <p>Permite a los usuarios visualizar detalles completos de las ofertas, inscribirse en ellas o
                  guardarlas
                  para referencia futura.</p>
              </li>
            </ul>
          </div>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>Tecnologias aplicadas</h2>
            <p class="margin-top">{{ descripcionAplicacion1 }}</p>
            <ul>
              <li class="margin-top">
                <h5><strong>Dart:</strong></h5>
                <p>Lenguaje de programación moderno y conciso utilizado principalmente para el desarrollo de
                  aplicaciones Flutter.</p>
              </li>
              <li class="margin-top">
                <h5><strong>GetIt:</strong></h5>
                <p>Contenedor de servicios y dependencias para Dart y Flutter, ideal para gestionar la inyección de
                  dependencias y la creación de objetos en la aplicación.</p>
              </li>
              <li class="margin-top">
                <h5><strong>DIO:</strong></h5>
                <p>Cliente HTTP para Dart y Flutter que permite realizar peticiones al servidor de forma eficiente y
                  segura.</p>
              </li>
              <li class="margin-top">
                <h5><strong>GoRouter:</strong></h5>
                <p>Enrutador de navegación para Flutter que facilita la definición y gestión de rutas en la aplicación,
                  permitiendo una navegación fluida entre pantallas.</p>
              </li>
              <li class="margin-top">
                <h5><strong>MVVM:</strong></h5>
                <p>Patrón de diseño arquitectónico utilizado en Flutter para separar la lógica de presentación de la
                  lógica de negocio y los datos, facilitando el desarrollo de aplicaciones escalables y mantenibles.</p>
              </li>
            </ul>
          </div>
          <div :class="'recuadro-tecnologias'">
            <div class="recuadro-proyectos">
              <h5>MVVM</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>DIO</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>GoRouter</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>GetIt</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Dart</h5>
            </div>
            <div>
              <img src="@/assets/icons/github.png" alt="Descripción de la imagen"
                style="height: 100px; cursor: pointer;" @click="abrirEnlace">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';

export default {
  name: 'ProyectosPage',
  components: {
    HeaderComponent,
  },
  methods: {
    abrirEnlace() {
      window.open('https://github.com/NicolasLavilla/AprexiPraxisFlutter.git', '_blank');
    }
  }
};
</script>

<script setup>
var descripcionAprexi1 = "Aprexi es una innovadora plataforma que actúa como un puente dinámico entre individuos en busca de empleo o nuevas oportunidades y las empresas que buscan talento. Su enfoque multisectorial no se limita a nichos específicos, sino que se abre a una amplia gama de industrias. Destacando especialmente como una valiosa herramienta para aquellos que están dando sus primeros pasos en el mundo laboral.";
var descripcionAplicacion1 = "En este proyecto de desarrollo de aplicaciones Flutter con Dart, hemos aplicado un conjunto de tecnologías modernas y prácticas para garantizar una experiencia de usuario fluida y una estructura de código mantenible. En la base de nuestra arquitectura se encuentra el patrón de diseño Model-View-ViewModel (MVVM), que separa claramente la lógica de presentación de la lógica de negocio y los datos.";

</script>

<style>
@import url('@/assets/styles/displacements.css');
@import url('@/assets/styles/gravity.css');
@import url('@/assets/styles/styles_colors.css');
@import url('@/assets/styles/styles_letter.css');
@import url('@/assets/styles/styles_margins.css');
@import url('@/assets/styles/responsive_screen.css');

.recuadro-proyectos {
  padding: 40px;
  max-width: 20%;
  background-color: var(--accent-color);
  border-radius: 15px;
  margin: 10px;
  margin-inline: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>