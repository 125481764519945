<template>
  <HeaderComponent></HeaderComponent>
  <div class="background">
    <div class="presentation">
      <div :class="'recuadro-presentacion'">
        <div>
          <div style="display: flex;">
            <h1>I'M </h1>
            <h1 :style="{ color: 'var(--accent-color)', marginLeft: '10px' }">{{ title_1 }}</h1>
          </div>
          <h1>{{ title_2 }}</h1>
          <p :style="{ marginTop: '25px' }">{{ subtitulo }}</p>
        </div>
        <div :class="'lenguages-icons'">
          <img src="../assets/images/android.png" alt="Android" :class="'responsive-image'">
          <img src="../assets/images/swift.png" alt="Swift" :class="'responsive-image'">
          <img src="../assets/images/vue.png" alt="Vue.js" :class="'responsive-image'">
          <img src="../assets/images/flutter.png" alt="Flutter" :class="'responsive-image'">
        </div>
      </div>

      <div :class="'recuadro-presentacion-imagen'">
        <img src="../assets/images/nicolas.png" alt="Descripción de la imagen"
          style="max-width: 100%; max-height: 100%;">
      </div>
    </div>
    <div class="definition">
      <h2>¿Quién soy?</h2>
      <div :class="'definition-recuadro'">
        <div class='definition-recuadro-left' style="min-width: 300px; "> <!--:class="'definition-recuadro-left'"-->
          <p>{{ parrafo }}</p>
          <p :style="{ marginTop: '20px' }">{{ parrafo1 }}</p>
          <div :class="'definition-recuadro-contacto'">
            <p :style="{ marginTop: '30px', marginRight: '15px' }" style="color: var(--text-color-primary);">Si quieres
              saber más de mi o tomar un café, pues...</p>
            <router-link to="/contacto">
              <ButtonComponent :style="{ marginTop: '30px' }">
                <h5>¡Contáctame!</h5>
              </ButtonComponent>
            </router-link>
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: center;"><!--:class="'definition-recuadro-rigth'"-->
          <img src="../assets/images/premio_transparente.png" alt="Premio Ganador Programa Emprendimiento"
            style="max-width: 600px; max-height: 800px; ">
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
var title_1 = ' MOBILE & WEB';
var title_2 = ' DEVELOPER';
var subtitulo = 'Mi enfoque se centra en el cliente. Mi pasión radica en la creación de aplicaciones que no solo cumplen con las expectativas, sino que también brindan experiencias excepcionales para los usuarios finales. Impulsado por la innovación, mi objetivo es llevar las ideas a la realidad, creando soluciones digitales impactantes y orientadas al éxito del cliente.';
var parrafo = 'Mi experiencia va más allá de la programación; soy un creador de soluciones digitales que fusiona creatividad y funcionalidad. Desde el diseño intuitivo hasta la implementación eficiente, cada proyecto que emprendo es una oportunidad para demostrar mi capacidad para transformar ideas en realidades digitales impactantes. Estoy aquí para potenciar su visión y elevar su presencia en línea a nuevos niveles de éxito.';
var parrafo1 = 'Como apasionado del desarrollo web y móvil, tengo un fuerte compromiso con la mejora constante. Estoy siempre explorando las últimas tendencias y tecnologías para ampliar mis conocimientos. Mi disposición para aprender y adoptar nuevas herramientas no solo me beneficia a mí, sino que también creo que aporta un valor significativo para enfrentar los desafíos tecnológicos en constante cambio. Me entusiasma continuar aprendiendo y aplicar soluciones innovadoras en cada proyecto que emprenda.';
</script>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import ButtonComponent from '../components/ButtonComponent.vue';

export default {
  name: 'HomePage',
  components: {
    HeaderComponent,
    ButtonComponent
  },
};
</script>

<style>
@import url('../assets/styles/displacements.css');
@import url('../assets/styles/gravity.css');
@import url('../assets/styles/styles_colors.css');
@import url('../assets/styles/styles_letter.css');
@import url('../assets/styles/styles_margins.css');
@import url('../assets/styles/responsive_screen.css');

.dark-mode {
  /* Importa los estilos oscuros desde styles_colors.css */
  @import url('../assets/styles/styles_colors.css');
}
</style>