<template>
  <router-link :to="{ name: url}">
    <div class="registro recuadro-proyects">
      <div class="image-container">
        <img :src="resolvedImage" :alt="altImage" class="img-full">
      </div>
      <div style="padding: 15px;">
        <div :style="{ display: 'flex', alignItems: 'baseline' }">
          <h3>{{ title }}</h3>
          <h5 style="color: var(--accent-color); margin-left: 30px">{{ tecnology }}</h5>
        </div>
        <p :style="{ marginTop: '10px' }">{{ description }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    tecnology: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    altImage: {
      type: String,
      required: false,
      default: ''
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    resolvedImage() {
      // Usamos require.context para resolver la ruta de la imagen
      try {
        return require(`@/assets/images/${this.image.split('/').pop()}`);
      } catch (e) {
        console.error('Image not found:', this.image);
        return '';
      }
    }
  }
};
</script>


<style scoped>
.registro {
  border: 1px solid #ddd;
  margin: 10px;
  border-radius: 5px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* Ajusta según tus necesidades */
  height: 300px;
  /* Ajusta según tus necesidades */
  overflow: hidden;
  background-color: #f0f0f0;
  /* Color de fondo opcional */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
