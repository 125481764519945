
<template>
    <div class="registro">
      <div :style="{display: 'flex',alignItems: 'baseline'}">
        <h3>{{ puesto }}</h3>
        <h5 style="color: var(--accent-color); margin-left: 30px">{{ nombreEmpresa }}</h5>
      </div>
      <div :style="{display: 'flex',alignItems: 'baseline'}">
        <h6 :style="{marginTop: '5px'}">{{ ubicacionEmpresa }}</h6>
        <h6 style="margin-left: 30px">{{ ano }}</h6>
      </div>
      <p :style="{marginTop: '10px'}">{{ descripcionPuesto }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      nombreEmpresa: String,
      ubicacionEmpresa: String,
      ano: String,
      puesto: String,
      descripcionPuesto: String,
    },
    methods: {
      
    },
  };
  </script>

  <style scoped>
  @import url('../assets/styles/styles_colors.css');
  .registro {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
  }
  </style>
