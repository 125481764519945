<template>
  <div :class="{ 'dark-mode': isDarkMode }">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    computed: {
      isDarkMode() {
        return this.$store.getters.isDarkMode;
      },
    },
  };
</script>

<style>
/* Estilos globales si es necesario */

</style>
