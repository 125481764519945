<template>
  <HeaderComponent></HeaderComponent>
  <div class="background">
    <div class="presentation">
      <div :class="'recuadro-presentacion-40-60'">
        <h1>Aprexi Android</h1>
        <div :style="{ display: 'flex', justifyContent: 'center' }">
          <div :class="'div-imagen'">
            <img src="@/assets/images/oo.png" alt="Descripción de la imagen" :class="'image-proyect'">
          </div>
        </div>
      </div>
      <div :class="'recuadro-presentacion-60-40'">
        <div :class="'descripcion-proyecto'">
          <h2>¿Qué es?</h2>
          <p class="margin-top">{{ descripcionAprexi1 }}</p>
          <p class="margin-top">{{ descripcionAprexi2 }}</p>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>¿Qué funcionalidades tiene?</h2>
            <p class="margin-top">{{ descripcionAprexi3 }}</p>
            <ul>
              <li class="margin-top">
                <h5><strong>Acceso seguro y fácil:</strong></h5>
                <p>Los usuarios pueden registrarse o iniciar sesión de manera rápida y segura, con opciones de registro
                  para alumnos y trabajadores.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Exploración de oportunidades:</strong></h5>
                <p>Ofrece un listado completo de ofertas de empleo y prácticas, con herramientas de filtrado que
                  permiten
                  a los usuarios encontrar fácilmente las oportunidades que mejor se adapten a sus necesidades.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Organización personalizada:</strong></h5>
                <p>Los usuarios pueden guardar empresas y ofertas de interés para un acceso rápido y fácil en el futuro.
                </p>
              </li>
              <li class="margin-top">
                <h5><strong>Seguimiento del proceso:</strong></h5>
                <p>Proporciona una visión clara del progreso de los usuarios en el proceso de solicitud, mostrando las
                  diferentes etapas, desde la visualización del CV hasta la inscripción y más allá.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Creación y descarga de CV personalizados:</strong></h5>
                <p>Permite a los usuarios crear CV profesionales, incorporando toda su información relevante,
                  como estudios, experiencia laboral, idiomas y más.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Interacción con las ofertas:</strong></h5>
                <p>Permite a los usuarios visualizar detalles completos de las ofertas, inscribirse en ellas o
                  guardarlas
                  para referencia futura.</p>
              </li>
            </ul>
          </div>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>Tecnologias aplicadas</h2>
            <p class="margin-top">{{ descripcionAplicacion1 }}</p>
            <ul>
              <li class="margin-top">
                <h5><strong>Kotlin:</strong></h5>
                <p>Lenguaje de programación moderno y conciso que se utiliza principalmente para el desarrollo de
                  aplicaciones Android.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Koin:</strong></h5>
                <p>Biblioteca de inyección de dependencias liviana para Kotlin, ideal para gestionar la creación y
                  provisión de objetos en la aplicación.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Retrofit:</strong></h5>
                <p>Librería HTTP para realizar peticiones al servidor de forma eficiente y segura.</p>
              </li>
              <li class="margin-top">
                <h5><strong>JUnit4:</strong></h5>
                <p>Framework de pruebas unitarias para Java, utilizado para probar la funcionalidad del código de manera
                  automática.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Corrutinas:</strong></h5>
                <p>Una característica de Kotlin para simplificar la programación asíncrona y concurrente, permitiendo la
                  ejecución de tareas de forma no bloqueante.</p>
              </li>
            </ul>

          </div>
          <div :class="'recuadro-tecnologias'">
            <div class="recuadro-proyectos">
              <h5>MVVM</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Inyección de dependencias</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Corrutines</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Test Unitarios</h5>
            </div>
            <div>
              <img src="@/assets/icons/github.png" alt="Descripción de la imagen"
                style="height: 100px; cursor: pointer;" @click="abrirEnlace">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';

export default {
  name: 'ProyectosPage',
  components: {
    HeaderComponent,
  },
  methods: {
    abrirEnlace() {
      window.open('https://github.com/NicolasLavilla/AprexiPraxisKt.git', '_blank');
    }
  }
};
</script>

<script setup>
var descripcionAprexi1 = "Aprexi es una innovadora plataforma que actúa como un puente dinámico entre individuos en busca de empleo o nuevas oportunidades y las empresas que buscan talento. Su enfoque multisectorial no se limita a nichos específicos, sino que se abre a una amplia gama de industrias. Destacando especialmente como una valiosa herramienta para aquellos que están dando sus primeros pasos en el mundo laboral.";
var descripcionAprexi2 = "La fuerza de Aprexi radica en su capacidad para facilitar la conexión entre empleadores y candidatos a través de filtros eficientes, simplificando el proceso de selección y proporcionando a las empresas acceso a un talento diverso y prometedor. Con Aprexi, se abre la puerta a oportunidades laborales emocionantes y se fomenta el crecimiento profesional en todos los niveles.";
var descripcionAplicacion1 = "En el corazón de la plataforma, he creado una experiencia nativa potente y eficiente para los usuarios de Android utilizando Kotlin. La aplicación está construida sobre una arquitectura moderna MVVM. Para gestionar la creación y provisión de objetos, utilizo Koin como biblioteca de inyección de dependencias. La comunicación con el servidor se realiza de forma eficiente y segura a través de Retrofit. Además, las pruebas unitarias se gestionan con JUnit4, y la programación asíncrona y concurrente se maneja mediante Corrutinas, garantizando una experiencia de usuario fluida y reactiva.";
</script>

<style>
@import url('@/assets/styles/displacements.css');
@import url('@/assets/styles/gravity.css');
@import url('@/assets/styles/styles_colors.css');
@import url('@/assets/styles/styles_letter.css');
@import url('@/assets/styles/styles_margins.css');
@import url('@/assets/styles/responsive_screen.css');
</style>