<template>
  <header :class="{ 'dark-mode': isDarkMode }">
    <h2>Nicolás Lavilla Calahorra</h2>
    <button class="btn-abrir" id="abrir">
      <img src="../assets/icons/menu.png" alt="Menú" style="width: 30px; height: 30px;" class="abrir-menu" />
    </button>
    
    <nav class="nav" id="nav">
      <button class="cerrar-menu" id="cerrar">
        <img src="../assets/icons/cruz.png" alt="Menú" style="width: 30px; height: 30px;" class="abrir-menu" />
      </button>
      <ul class="nav-list">
        <li>
          <button @click="toggleDarkMode">
            <img src="../assets/icons/modo-oscuro.png" alt="Modo Claro" style="width: 20px; height: 20px;" />
          </button>
        </li>
        <li><router-link to="/"><h6>¿Quién soy?</h6></router-link></li>
        <li><router-link to="/proyectos"><h6>Proyectos</h6></router-link></li>
        <li><router-link to="/experiencia"><h6>Experiencia</h6></router-link></li>
        <li><router-link to="/curriculum"><h6>Currículum</h6></router-link></li>
        <li><router-link to="/contacto">
              <ButtonComponent>
                <h5>Contacto</h5>
              </ButtonComponent>
            </router-link>
        </li>
      </ul>
      
    </nav>
  </header>
</template>

<script>
import ButtonComponent from './ButtonComponent.vue';

export default {
  methods: {
    toggleDarkMode() {
      this.$store.dispatch('toggleDarkMode');
    },
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  components: { 
    ButtonComponent,
  },

  mounted() {
    const nav = document.querySelector("#nav");
    const abrir = document.querySelector("#abrir");
    const cerrar = document.querySelector("#cerrar");

    abrir.addEventListener("click", () => {
      nav.classList.add("visible");
    });

    cerrar.addEventListener("click", () => {
      nav.classList.remove("visible");
    });
  },
};



</script>

<style scoped>

  @import url('../assets/styles/displacements.css');
  @import url('../assets/styles/gravity.css');
  @import url('../assets/styles/styles_colors.css');
  @import url('../assets/styles/styles_letter.css');
  @import url('../assets/styles/styles_margins.css');
  @import url('../assets/styles/responsive_screen.css');

header {
  padding: 9px 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-background);
}



.nav-list {
  list-style-type: none;
}

.nav-list li a{
  text-decoration: none;
}

ul{
    display: flex;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    align-items: center;
}


/* Estilos específicos para el modo oscuro */
.dark-mode {
  /* Importa los estilos oscuros desde styles_colors.css */
  @import url('../assets/styles/styles_colors.css');
}
</style>
