<template>
  <HeaderComponent></HeaderComponent>
  <div class="background">
    <div class="presentation" style=" flex-direction: column;">
      <h1 class="margins-left margins-top">BackEnd Node.js</h1>
      <div style="display: flex; flex-direction: column;">
        <div class="margins-left" style="max-width: 1100px;">
          <Carousel>
            <Slide v-for="(image, index) in images" :key="index">
              <div class="carousel__item">
                <img :src="image" :alt="'Imagen ' + (index + 1)" />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </div>
        <div :class="'descripcion-proyecto'">
          <h2>¿Qué es?</h2>
          <p class="margin-top">{{ descripcion1 }}</p>
          <p class="margin-top">{{ descripcion2 }}</p>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>API REST</h2>
            <p class="margin-top">{{ descripcionApi1 }}</p>
            <ul>
              <li class="margin-top">
                <h5><strong>POST - LoginUser:</strong></h5>
                <p>LoginUser proporciona una ruta segura para que los usuarios inicien sesión en nuestra plataforma, permitiendo una autenticación robusta y confiable.
                </p>
              </li>
              <li class="margin-top">
                <h5><strong>PUT - UpdateUser:</strong></h5>
                <p>UpdateUser, hemos habilitado la capacidad de actualizar la información del usuario, brindando una experiencia personalizada y actualizada a nuestros clientes.
                </p>
              </li>
              <li class="margin-top">
                <h5><strong>GET - UserDataById:</strong></h5>
                <p>Nos permiten acceder a información específica de usuarios con el Id.
                </p>
              </li>
              <li class="margin-top">
                <h5><strong>GET - AllUserData:</strong></h5>
                <p>Nos permiten acceder a información específica de usuarios y obtener una vista general de todos los datos de usuario almacenados en nuestra base de datos, respectivamente.
                </p>
              </li>
            </ul>
            <p class="margin-top">{{ descripcionApi2 }}</p>
          </div>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>Tecnologias aplicadas</h2>
            <p class="margin-top">{{ descripcionAplicacion1 }}</p>
            <ul>
              <li class="margin-top">
                <h5><strong>Node.js:</strong></h5>
                <p>Entorno de ejecución de JavaScript que permite crear aplicaciones de red escalables y eficientes.
                </p>
              </li>
              <li class="margin-top">
                <h5><strong>Express.js:</strong></h5>
                <p>Framework web minimalista y flexible para Node.js, utilizado para crear aplicaciones web y APIs de
                  forma rápida y sencilla.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Jest:</strong></h5>
                <p>Framework de pruebas unitarias para JavaScript, utilizado para realizar pruebas de forma rápida y
                  sencilla en aplicaciones Node.js y Express.js.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Sequelize:</strong></h5>
                <p>ORM (Object-Relational Mapping) para Node.js, utilizado para interactuar con bases de datos
                  relacionales de manera sencilla y eficiente.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Jsonwebtoken:</strong></h5>
                <p>Librería para Node.js que permite generar y verificar tokens JWT (JSON Web Tokens) para implementar
                  autenticación y autorización seguras en aplicaciones web.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Awilix:</strong></h5>
                <p>Contenedor de inyección de dependencias para Node.js, utilizado para gestionar la creación y
                  provisión de objetos de manera eficiente y desacoplada.</p>
              </li>
              <li class="margin-top">
                <h5><strong>Dotenv:</strong></h5>
                <p>Librería para Node.js que facilita la carga de variables de entorno desde archivos .env para
                  configurar aplicaciones de forma segura y portátil.</p>
              </li>
            </ul>
          </div>
          <div :class="'recuadro-tecnologias'">
            <div class="recuadro-proyectos">
              <h5>ORM Sequalize</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Inyección de dependencias</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>API Rest</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Test Unitarios</h5>
            </div>
            <div>
              <img src="@/assets/icons/github.png" alt="Descripción de la imagen"
                style="height: 100px; cursor: pointer;" @click="abrirEnlace">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'ProyectoBackEndNodejs',
  components: {
    HeaderComponent,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    abrirEnlace() {
      window.open('https://github.com/NicolasLavilla/BackEnd_Node.js/tree/backend_nodejs', '_blank');
    }
  },
  data() {
    return {
      images: [
        require('../../assets/images/proyects/proyectBackEndNodejs/visualstudio_routes.png'),
        require('../../assets/images/proyects/proyectBackEndNodejs/postMan_login.png'),
      ]
    }
  }
};
</script>

<script setup>
var descripcion1 = "Este proyecto de backend con Node.js es una plataforma robusta y escalable que sirve como motor para aplicaciones web y APIs. Utilizando Node.js, hemos construido un entorno de ejecución eficiente que permite el desarrollo rápido y ágil de soluciones backend. Nuestro enfoque se centra en la creación de una arquitectura sólida y flexible que garantice la seguridad, la escalabilidad y el rendimiento óptimo.";
var descripcion2 = "Con Node.js como base, hemos integrado tecnologías líderes como Express.js, Sequelize y Jsonwebtoken para gestionar las solicitudes HTTP, interactuar con la base de datos y garantizar la autenticación segura. Este proyecto backend es la columna vertebral de nuestra aplicación, proporcionando la infraestructura necesaria para soportar operaciones críticas y ofrecer una experiencia fluida a los usuarios finales.";
var descripcionAplicacion1 = "En este proyecto, hemos utilizado tecnologías líderes como Express.js, Jest, Sequelize y Jsonwebtoken para construir una aplicación web segura y eficiente en Node.js. Express.js nos proporciona una arquitectura sólida para manejar peticiones HTTP, Jest garantiza la calidad del código con pruebas unitarias, Sequelize simplifica la interacción con la base de datos y Jsonwebtoken asegura una autenticación segura. Con estas herramientas, he creado una aplicación confiable y de alto rendimiento.";
var descripcionApi1 = "La API REST desarrollada en Node.js ofrece una gama de servicios esenciales para la gestión de usuarios. Implementando métodos HTTP estándar como POST, PUT y GET, hemos creado una arquitectura sólida que abarca desde la autenticación hasta la obtención de datos de usuario.";
var descripcionApi2 = "Aunque estos puntos pueden parecer simples, detrás de cada uno de ellos hemos implementado una arquitectura completa que garantiza la seguridad, la eficiencia y la escalabilidad de nuestra API. Desde la gestión de rutas hasta la validación de datos y la integración con la base de datos, hemos construido una API robusta que cumple con los estándares de desarrollo modernos y proporciona una experiencia de usuario fluida y satisfactoria.";
</script>

<style>
@import url('@/assets/styles/displacements.css');
@import url('@/assets/styles/gravity.css');
@import url('@/assets/styles/styles_colors.css');
@import url('@/assets/styles/styles_letter.css');
@import url('@/assets/styles/styles_margins.css');
@import url('@/assets/styles/responsive_screen.css');
</style>