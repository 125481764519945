<script setup>
    import HeaderComponent from '../components/HeaderComponent.vue';
    
    var introduccion = "Como profesional altamente motivado y orientado a resultados, poseo habilidades interpersonales excepcionales y una actitud proactiva. Mi enfoque orientado al cliente y mi capacidad para adaptarme rápidamente a nuevas situaciones me han permitido alcanzar el éxito en entornos dinámicos. Con una actitud positiva y una mentalidad de mejora constante, estoy entusiasmado por contribuir al crecimiento de su empresa.";
    
    var permisoTrabajo = "Nacional";
    var vehiculoPropio = "Si";
    var altaAutonomo = "No";

    var redesSociales =[
        {rs: 'linkedin.NicolasLavilla', enlace:'https://www.linkedin.com/in/nicolas-lavilla-calahorra/'}, 
        {rs: 'github.NicolasLavilla', enlace:'https://github.com/NicolasLavilla'},
    ];

    /*var listReferencias = [
        {listReferencia: 'Laura Carrasco - 635260245'},
        {listReferencia: 'Mario Morales - 644135254'},
        {listReferencia: 'Silvia Gimeno - 626543942'},
    ];*/

    var Idioma = [
        {nombreIdioma:'Castellano - Nativo'},
        {nombreIdioma:'Catalán - Nativo'},
        {nombreIdioma:'Inglés - Básico'},
    ];

    var carnets = [
        {nombreCarnet: 'Coche - B'},
    ];

    var Nombre = "Nicolás";
    var Apellido1 = "Lavilla";
    var Apellido2 = "Calahorra";

    var profesion = "Mobile Developer";

    var municipio = "Calatayud, Zaragoza";
    //var telefono = 615917925;
    var correo = "nicolaslavillacalahorra@gmail.com";

    //var genero = "Hombre";
    var fechaNacimiento = "11/02/1998";

    var registrosProfesionales = [
        { nombreEmpresa: 'Arco Electrónica', ubicacionEmpresa: 'La Almunia de Doña Godina', ano: '2023-2023', puesto: 'Desarrollador de Aplicaciones',  descripcionPuesto: 'En este puesto desarrollé nuevos aplicativos web relacionados con el análisis de flotas, desde la creacion de Base de Datos, hasta el diseño de la interfaz y flujo de la aplicación. Fué una experiencia breve, pero aprendí mucho.'},
        { nombreEmpresa: 'Ibermática', ubicacionEmpresa: 'Bilbao', ano: '2022-2023', puesto: 'Desarrollador Web Java Junior', descripcionPuesto: 'Realizé mantenimientos de la plataforma, utilicé frameworks de Java como Struts2, un SGBD Oracle y Subversion como control de versiones.'}
    ];

    var registrosEstudios = [
        { nombreEscuela: 'Hiberus', ubicacionEscuela: 'Zaragoza', ano: '2023-2023', curso: 'Curso en tecnologias Móviles (Android, iOS, Flutter)', descripcionCurso: 'Este curso de 300h impartido por porfesionales de una consultora tecnológica, me permitieron aprender las últimas nociones de arquitectura, inyección de dependencias, tareas en segundo plano, entre otras muchas cosas de las distintas plataformas (Android, iOs y Flutter).'},
        { nombreEscuela: 'Ilerna', ubicacionEscuela: 'Lleida', ano: '2021-2022', curso: 'G.S Desarrollo de Aplicaciones Multiplataforma', descripcionCurso: 'Estos estudios me permitieron diseñar, desarrollar y mantener de aplicaciones informáticas que puedan ejecutarse en diferentes plataformas, como dispositivos móviles, sistemas web y sistemas de escritorio.'},
        { nombreEscuela: 'SEAS', ubicacionEscuela: 'Zaragoza', ano: '2020-2021', curso: 'Experto Universitario en IOT, Big Data e Industria 4.0',  descripcionCurso: 'Al realizar este curso, pude conocer todo el entramado de tecnologías de telecomunicaciones que permite extraer información y adquirir el conocimiento de los datos para su posterior manipulación.'},
        { nombreEscuela: 'IES Bernat el Ferrer', ubicacionEscuela: 'Molins de Rei', ano: '2018-2020', curso: 'G.S Automatización y Robótica Industrial',  descripcionCurso: 'Estos estudios me capacitaron para diseñar y programar instalaciones automatizadas, desde su modelo de negocio hasta su intalación con robots y automatas'}
    ];

    var lenguajesProgramacion = [
        { idHabilidad: 0 , nombreSkill:'Java', numExperiencia: 1 , experiencia: 'Media'},
        { idHabilidad: 1 , nombreSkill:'HTML, CSS y JavaScript', numExperiencia: 2 , experiencia: 'Media'},
        { idHabilidad: 2 , nombreSkill:'Framework - Vue.js', numExperiencia: 1 , experiencia: 'Alta'},
        { idHabilidad: 3 , nombreSkill:'Node.js', numExperiencia: 1 , experiencia: 'Alta'},
        { idHabilidad: 4 , nombreSkill:'SQL', numExperiencia: 3 , experiencia: 'Baja'},
        { idHabilidad: 5 , nombreSkill:'Android / Kotlin', numExperiencia: 2 , experiencia: 'Alta'},
        { idHabilidad: 6 , nombreSkill:'iOS / Swift', numExperiencia: 3 , experiencia: 'Alta'},
        { idHabilidad: 7 , nombreSkill:'Flutter / Dart', numExperiencia: 3 , experiencia: 'Alta'},
    ];

    var proyectosRealizados = [
        { titulo: 'Aprexi' , descripcion: 'En este proyecto se han realizado labores de diseño del modelo de negocio, BBDD, BackEnd, APP movil (Android, iOS y Flutter) además de Web hecho con vue.js. Es una aplicación que pone en contacto a usuarios que buscan empleo como reclutardores de diversos sectores y corporaciones.'},
        { titulo: 'Ecommerce Lavilla Calahorra' , descripcion: 'He creado una plataforma Ecommerce para un pequeño negocio con PrestaShop, con pasarela de pagos y diseño integrado de la plataforma.'},
    ];

</script>

<script>
import ButtonComponent from '../components/ButtonComponent.vue';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import QRCode from "qrcode";

export default { 
    
  methods: {
    ButtonComponent,
    async generarPDF() {
      // Obtenemos el contenido del div que queremos descargar
      const contenido = this.$refs.divParaPDF;

      // Convertimos el contenido a una imagen utilizando html2canvas con mayor resolución (scale)
      const options = {
        scale: 2, // Aumentar la escala del canvas (2 es el doble de resolución)
        logging: false, // Deshabilitar los mensajes de log para evitar mensajes en la consola
      };
      const canvas = await html2canvas(contenido, options);

      // Creamos un nuevo objeto jsPDF
      const doc = new jsPDF("p", "mm", "a4"); // Usamos el formato A4

      // Obtenemos la URL de la imagen generada por html2canvas en formato PNG sin pérdida de calidad
      const imgData = canvas.toDataURL("image/jpeg", 0.7); // Reducimos la calidad de la imagen para reducir el tamaño del archivo

      // Ajustamos el tamaño de la imagen al tamaño de la página A4
      const pageWidth = doc.internal.pageSize.getWidth();
      //const pageHeight = doc.internal.pageSize.getHeight();
      const imageWidth = pageWidth; // Sin márgenes, la imagen ocupa toda la página
      const imageHeight = (canvas.height * imageWidth) / canvas.width;

      // Insertamos la imagen en el PDF
      doc.addImage(imgData, "JPEG", 0, 0, imageWidth, imageHeight);

      // Descargamos el PDF con un nombre específico
      doc.save("mi_CV.pdf");
    },
  },

  /*Para colocar el QR*/
  mounted() {
    // Contenido que deseas codificar en el código QR
    const contenidoQR = "nicolaslavillacalahorra.es";

    // Generar el código QR dentro del elemento con el ID "codigo-qr"
    QRCode.toCanvas(document.getElementById("codigo-qr"), contenidoQR, {
      width: 120,
      height: 120,
    });
  },


};
</script>

<template>
<div class="container">
    <nav>
        <HeaderComponent></HeaderComponent>
    </nav>  
    <main class="background">
        <div class="contendor-global-escritorio contendor-escritorio"> 
            <div class="menu-lateral-escritorio" style="width: 15%;">
                
            </div>
            <div style="width: 70%; max-width: 1000px; margin-left: auto; margin-right: auto;">
                <div ref="divParaPDF" class="recuadro desplazamiento-derecha-stretch" style="min-width: 950px; min-height:297mm ;">
                    <div class="desplazamiento-vertical-cv recuadro-general-izquierda">
                        <input type="file" ref="fileInput" @change="handleFileChange" accept="image/*" style="display: none">
                        <img class="foto" src="../assets/images/foto_cv.jpg" alt="Descripción de la imagen" @click="openFileInput" style="cursor: pointer;">
                        <div class="desplazamiento-vertical-cv estilo-izquierdo">
                            <h3 style="font-weight: 500;" class="letter-dark">Sobre mí:</h3>
                            <p style="font-size: 12px; margin-top: 15px;" class="letter-dark">{{ introduccion }}</p>
                        </div>
                        <div class="linea-separatoria"></div>
                        <div class="desplazamiento-vertical-cv estilo-izquierdo">

                            <h3 style="font-weight: 500;" class="letter-dark">Redes Sociales:</h3>
                            <div v-for="registro in redesSociales" :key="registro.rs">
                                <a :href="registro.enlace" target="_blank">
                                    <p style="font-size: 12px; margin-top: 15px; cursor: pointer;" class="letter-dark">{{ registro.rs }}</p>
                                </a>
                            </div>

                        </div>
                        <div class="linea-separatoria"></div>
                        <!--<div class="desplazamiento-vertical-cv estilo-izquierdo">
                            <h3 style="font-weight: 500;" class="letter-dark">Referencias:</h3>
                            <div v-for="registro in listReferencias " :key="registro.listReferencia">
                                <p style="font-size: 12px; margin-top: 15px;" class="letter-dark" >{{ registro.listReferencia }}</p>
                            </div>
                        </div>-->
                        <div class="linea-separatoria"></div>
                        <div  class="desplazamiento-vertical-cv estilo-izquierdo">
                            <h3 style="font-weight: 500;" class="letter-dark">Idiomas:</h3>
                            <div v-for="registro in Idioma " :key="registro.nombreIdioma">
                                <p style="font-size: 12px; margin-top: 17px ;" class="letter-dark" >{{ registro.nombreIdioma }}</p>
                            </div>
                        </div>
                        <div class="linea-separatoria"></div>
                        <div  class="desplazamiento-vertical-cv estilo-izquierdo">
                            <h3 style="font-weight: 500;" class="letter-dark">Carnets:</h3>
                            <div v-for="registro in carnets " :key="registro.nombreCarnet">
                                <p style="font-size: 12px; margin-top: 17px ;" class="letter-dark" >{{ registro.nombreCarnet }}</p>
                            </div>
                        </div>
                        <div class="linea-separatoria"></div>
                        <div  class="desplazamiento-vertical-cv estilo-izquierdo">
                            <h3 style="font-weight: 500;" class="letter-dark">Lenguajes de Programación:</h3>
                            <div v-for="registro in lenguajesProgramacion" :key="registro.id">
                                <p style="font-size: 12px; margin-top: 17px ;" class="letter-dark" >{{ registro.nombreSkill }}</p>
                            </div>
                        </div>
                        <div class="linea-separatoria"></div>
                        <div  class="desplazamiento-vertical-cv estilo-izquierdo">
                            <p style="font-size: 12px; margin-top: 17px ;" class="letter-dark">¿Permiso de trabajo? {{ permisoTrabajo }}</p>
                            <p style="font-size: 12px; margin-top: 17px ;" class="letter-dark">¿Vehiculo Propio? {{ vehiculoPropio }}</p>
                            <p style="font-size: 12px; margin-top: 17px ;" class="letter-dark">¿Alta Autonomo? {{ altaAutonomo }}</p>
                        </div>
                        
                    </div>
                    
                    <div class="desplazamiento-vertical-cv recuadro-general-derecha">
                        <!--NOMBRE Y APELLIDOS-->
                        <div class="desplazamiento-derecha-stretch" style="width: 100%;">
                            <div class="estilo-derecho" style="padding-top: 50px; width: 50%;">
                                <h1 class="estilo-nombre letter-white">{{ Nombre }}</h1>
                                <div style="display: flex; ">
                                    <h1 class="estilo-nombre letter-white">{{ Apellido1 }}</h1>
                                    <h1 class="estilo-nombre letter-white" style="margin-left: 8px;">{{ Apellido2 }}</h1>
                                </div>
                                
                                <h3 class="letter-white">{{ profesion }}</h3>
                            </div>
                            <div class="estilo-derecho amplitud-contenido" style="padding-top: 50px; width: 50%;">
                                <canvas id="codigo-qr"></canvas>
                            </div>
                        </div>
                        <div class="desplazamiento-derecha-stretch amplitud-contenido-horizontal" style="width: 94%;">
                            <h4 style="max-width: 200px" class="letter-white">{{ municipio }}</h4>
                            <h4 style="max-width: 0px" class="letter-white">{{ telefono }}</h4>
                            <h4 style="max-width: 230px" class="letter-white">{{ correo }}</h4>
                            <h4 style="max-width: 0" class="letter-white">{{ genero }}</h4>
                            <h4 style="max-width: 120px" class="letter-white">{{ fechaNacimiento }}</h4>
                        </div>
                        <div class="linea-separatoria-derecha"></div>
                        <div style="width: 86%;">
                            <h2 style="font-weight: 600; margin-top: 30px;" class="letter-white">Experiencia Profesional:</h2>
                            <div v-for="registro in registrosProfesionales  " :key="registro.id">
                                
                                <div class="desplazamiento-derecha-stretch" style="margin-top: 15px;">
                                    <div style="width: 20%;">
                                        <!--Nombre Empresa-->
                                        <h3 style="font-weight: 600;" class="letter-white">{{ registro.nombreEmpresa }}</h3>
                                        <p class="letter-white">{{ registro.ubicacionEmpresa }}</p>
                                        <p class="letter-white">{{ registro.ano }}</p> 
                                    </div>
                                    <div style="width: 80%;">
                                        <h3 style="font-weight: 600;" class="letter-white">{{ registro.puesto }}</h3>
                                        <p class="letter-white"> {{ registro.descripcionPuesto }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="linea-separatoria-derecha" style="margin-top: 30px;"></div>
                        <div style="width: 86%;">
                            <h2 style="font-weight: 600; margin-top: 30px;" class="letter-white">Estudios:</h2>
                            <div v-for="registro in registrosEstudios" :key="registro.id">
                                
                                <div class="desplazamiento-derecha-stretch" style="margin-top: 15px;">
                                    <div style="width: 20%;">
                                        <!--Nombre Empresa-->
                                        <h3 style="font-weight: 600;" class="letter-white">{{ registro.nombreEscuela }}</h3>
                                        <p class="letter-white">{{ registro.ubicacionEscuela }}</p>
                                        <p class="letter-white">{{ registro.ano }}</p> 
                                    </div>
                                    <div style="width: 80%;">
                                        <h3 style="font-weight: 600;" class="letter-white">{{ registro.curso }}</h3>
                                        <p class="letter-white"> {{ registro.descripcionCurso }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="linea-separatoria-derecha" style="margin-top: 30px;"></div>
                        <div style="width: 86%;">
                            <h2 style="font-weight: 600; margin-top: 30px;" class="letter-white">Proyectos Realizados:</h2>
                            <div class="desplazamiento-derecha-stretch" style="justify-content:space-around">
                                <div v-for="registro in proyectosRealizados" :key="registro.titulo">
                                
                                    <div  style="margin-top: 15px;">
                                            <h3 style="font-weight: 600;" class="letter-white">{{ registro.titulo }}</h3>
                                            <p class="letter-white">{{ registro.descripcion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 15%;">
                <ButtonComponent @click="generarPDF()">
                    <h3>Descargar PDF</h3>
                </ButtonComponent>
            </div>
        </div>
    </main>

    </div>
</template>  

<style scoped>
@import url('../assets/styles/curriculum.css');
.estilo-nombre{
    font-weight: 700;
    font-family:  'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.linea-separatoria{
    width: 80%;
    border-top: var(--vt-c-color-letra-huevo-roto) 0.1px solid;
}
.linea-separatoria-derecha{
    width: 90%;
    border-top: var(--vt-c-color-letra-oscuro) 0.1px solid;
}

.estilo-izquierdo{
    padding: 8% 10% 8% 10%;
    color: var(--vt-c-color-letra-huevo-roto);
}
.estilo-derecho{
    padding: 7% 7% 3% 7%;
    color: var(--vt-c-color-letra-oscuro);
}

.amplitud-contenido{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.amplitud-contenido-horizontal{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.desplazamiento-vertical-cv{
    display: flex;
    flex-direction: column;
    align-items: center;
  } 

.foto{
    margin-top: 40px;
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.recuadro-general-izquierda{
    width: 30%; 
    height: 1415px; 
    background-color: var(--vt-c-color-fondo-izq-cv);
    border-radius: 8px 0px 0px 8px;
}

.recuadro-general-derecha{
    width: 70%;
    height: 1415px;
    background-color: #ffffff;
    border-radius: 0px 8px 8px 0px;
}

.recuadro{
    background-color: white;
    border-radius: 8px;
    color: #7C858E;
    border: 0.5px solid #BAC9D6;
    font-size: 15px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.menu-lateral-escritorio{
    width: 20%;
}

.contendor-escritorio{
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.letter-dark{
    color: var(--text-color-primary-dark-cv)
}

.letter-white{
    color: var(--text-color-primary-claro-cv)
}

</style>