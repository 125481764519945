import { createRouter, createWebHistory } from 'vue-router';

import Home from './views/HomePage.vue';
import Contacto from './views/ContactoPage.vue';
import Curriculum from './views/CurriculumPage.vue';
import Proyectos from './views/ProyectsPage.vue';
import Experiencia from './views/ExperienciaPage.vue';

import AprexiAndroid from './views/proyects/ProyectAprexiAppAndroidPage.vue';
import AprexiIOs from './views/proyects/ProyectAprexiAppIOSPage.vue';
import AprexiFlutter from './views/proyects/ProyectAprexiAppFlutterPage.vue';
import AprexiWeb from './views/proyects/ProyectAprexiWebPage.vue';
import NodeJs from './views/proyects/ProyectBackEndNodejsPage.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: Contacto
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    component: Curriculum
  },
  {
    path: '/proyectos',
    name: 'proyectos',
    component: Proyectos
  },
  {
    path: '/proyectos/AprexiAndroid',
    name: 'aprexiAndroid',
    component: AprexiAndroid
  },
  {
    path: '/proyectos/AprexiIOS',
    name: 'aprexiIOS',
    component: AprexiIOs
  },
  {
    path: '/proyectos/AprexiFlutter',
    name: 'aprexiFlutter',
    component: AprexiFlutter
  },
  {
    path: '/proyectos/AprexiWeb',
    name: 'aprexiWeb',
    component: AprexiWeb
  },
  {
    path: '/proyectos/BackEndNode',
    name: 'backEndNodeJs',
    component: NodeJs
  },
  {
    path: '/experiencia',
    name: 'experiencia',
    component: Experiencia
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

