<template>
  <div class="registro">
    <div :style="{ display: 'flex', alignItems: 'baseline' }">
      <h3>{{ curso }}</h3>
      <a :href="urlEscuela" target="_blank">
        <h5 style="color: var(--accent-color); margin-left: 30px">{{ nombreEscuela }}</h5>
      </a>
    </div>
    <div :style="{ display: 'flex', alignItems: 'baseline' }">
      <h6 :style="{ marginTop: '5px' }">{{ ubicacionEscuela }}</h6>
      <h6 style="margin-left: 30px">{{ ano }}</h6>
    </div>
    <p :style="{ marginTop: '10px' }" v-html="descripcionCurso"></p><!--{{ descripcionCurso }}-->
  </div>
</template>

<script>
export default {
  props: {
    nombreEscuela: String,
    ubicacionEscuela: String,
    urlEscuela: URL,
    ano: String,
    curso: String,
    descripcionCurso: String,
  },
  methods: {

  },
};
</script>

<style scoped>
.registro {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}
</style>
