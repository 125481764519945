<template>
    <button :class="[buttonClasses, 'gravity_center_center', 'button' ]" @click="handleClick">
      <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
    props: {
      buttonClasses: String,  // Atributo para clases CSS
    },
    methods: {
     handleClick() {
       //this.$emit('click');  // Emite un evento click al hacer clic en el botón
      },
    },
  };
  </script>
  
  <style scoped>

  @import url('../assets/styles/displacements.css');
  @import url('../assets/styles/gravity.css');
  @import url('../assets/styles/styles_colors.css');
  @import url('../assets/styles/styles_letter.css');
  @import url('../assets/styles/styles_margins.css');

  /* Estilos específicos del botón (puedes añadir más según tus necesidades) */
  .button {
    background-color: var(--accent-color);
    border: 1px solid var(--border-background);
    cursor: pointer;
    border-radius: 50px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px; 
  }
  
  /* Añade estilos adicionales basados en las clases proporcionadas */
  </style>
  