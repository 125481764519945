<template>
  <div>
    <HeaderComponent></HeaderComponent>
    <div class="background">
      <div :class="'presentation'">
        <div :class="'div-formulario'">
          <h1>Formulario de Contacto</h1>
          <form ref="contactForm">
            <p :style="{ marginTop: '15px' }"><label for="nombre">Nombre:</label></p>
            <input class="input-form" type="text" v-model="nombre" required>

            <p :style="{ marginTop: '15px' }"><label for="correo">Correo electrónico:</label></p>
            <input class="input-form" type="email" v-model="correo" required>

            <p :style="{ marginTop: '15px' }"><label for="mensaje">Mensaje:</label></p>
            <textarea :style="{ borderRadius: '10px', height: '120px',width: '100%', maxWidth: '550px', minWidth: '200px' }"
              v-model="mensaje" required></textarea>

            <ButtonComponent @click="enviarFormulario" :style="{ marginTop: '15px' }">
              <h3>Enviar</h3>
            </ButtonComponent>

            <div v-if="envioExitoso" style="color: green; margin-top: 10px;">
              ¡Formulario enviado con éxito!
            </div>
            <div v-if="errorEnvio" style="color: red; margin-top: 10px;">
              Error al enviar el formulario. Inténtalo de nuevo.
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import ButtonComponent from '../components/ButtonComponent.vue';

export default {
  name: 'ContactoPage',
  components: { HeaderComponent, ButtonComponent },
  data() {
    return {
      nombre: '',
      correo: '',
      mensaje: '',
      envioExitoso: false,
      errorEnvio: false,
      errorMessage: '',
      botonDeshabilitado: false // Agregamos una propiedad para controlar si el botón está deshabilitado
    };
  },
  methods: {
    enviarFormulario() {
      if (this.botonDeshabilitado) return; // Evita enviar el formulario si el botón ya está deshabilitado
      const formData = new FormData();
      formData.append('nombre', this.nombre);
      formData.append('correo', this.correo);
      formData.append('mensaje', this.mensaje);

      fetch('/enviar_correo.php', {
        method: 'POST',
        body: formData
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Error al enviar el formulario');
          }
          return response.json();
        })
        .then(data => {
          if (data.success) {
            this.envioExitoso = true;
            this.errorEnvio = false;
            this.botonDeshabilitado = true; // Deshabilita el botón después de enviar el formulario con éxito
          } else {
            this.envioExitoso = false;
            this.errorEnvio = true;
            this.errorMessage = data.message;
          }
        })
        .catch(error => {
          console.error('Error al enviar los datos:', error);
          this.envioExitoso = false;
          this.errorEnvio = true;
          this.errorMessage = 'Error al enviar el formulario. Inténtalo de nuevo.';
        });
    },
  },
};
</script>


  
<style>
@import url('../assets/styles/displacements.css');
@import url('../assets/styles/gravity.css');
@import url('../assets/styles/styles_colors.css');
@import url('../assets/styles/styles_letter.css');
@import url('../assets/styles/styles_margins.css');
@import url('../assets/styles/responsive_screen.css');

.input-form {
  border-radius: 10px;
  max-width: 300px;
  height: 20px;
  width: 100%;
}
</style>
  